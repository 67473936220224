import React, { Suspense, lazy, useCallback, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import GlobalStyles from "./global-styles/GlobalStyles";
import GlobalButtonStyles from "./global-styles/GlobalButtonStyles";
import { loadCurrentUser, setIsLoggedIn, jsonApi } from "./actions";
import { refreshToken } from "./helpers/jwt";
import withTracker from "./withTracker";
import GenericError from "./components/Common/GenericError";
import NeedsRefreshIndicator from "./components/NeedsRefreshIndicator";
import { initialiseGa } from "./helpers/trackEvent";
import requiresAuth from "./hoc/requiresAuth";
import { AppLayout } from "./layouts";
import { BackendApi, ApiContext, InnovatorApiContext } from "./api/ApiContext";
import axios from "axios";
import getBaseUrl from "./components/Unearthed/getBaseUrl";
import { setGlobalBackendApi } from "./globalBackendApi";
import LoadingPage from "./pages/LoadingPage";

const PageNotFound404 = lazy(() => import("./pages/PageNotFound404"));
const NewUser = lazy(() => import("./pages/Admin/NewUser"));
const EditContentBlock = lazy(() => import("./pages/Admin/EditContentBlock"));
const EditOrganisation = lazy(() => import("./pages/Admin/EditOrganisation"));
const EditTaskTemplate = lazy(() => import("./pages/Admin/EditTaskTemplate"));
const ManageContent = lazy(() => import("./pages/Admin/ManageContent"));
const ManageHelp = lazy(() => import("./pages/Admin/ManageHelp"));
const ManageOrganisations = lazy(
    () => import("./pages/Admin/ManageOrganisations"),
);
const ManageTags = lazy(() => import("./pages/Admin/ManageTags"));
const ManageTaskTemplates = lazy(
    () => import("./pages/Admin/ManageTaskTemplates"),
);
const ManageUsers = lazy(() => import("./pages/Admin/ManageUsers"));
const CompetitionRun = lazy(() => import("./pages/CompetitionRun"));
const EditUser = lazy(() => import("./pages/EditUser"));
const Home = lazy(() => import("./pages/Home"));
const KanbanColumnList = lazy(() => import("./pages/KanbanColumnList"));
const IdeasKanbanOverview = lazy(() => import("./pages/KanbanOverview").then(module => ({ default: module.IdeasKanbanOverview })));
const ChallengeTilesList = lazy(() => import("./pages/ChallengeTilesList"));
const LoginPage = lazy(() => import("./pages/LoginPage"));
const PasswordResetPage = lazy(() => import("./pages/PasswordResetPage"));
const PhaseEditPage = lazy(() => import("./pages/PhaseEditPage"));
const SetNewPassword = lazy(() => import("./pages/SetNewPassword"));
const ViewChallenge = lazy(() => import("./pages/ViewChallenge"));

let gaInit = initialiseGa();

interface Path {
    path: string | string[];
    component: React.FC | any;
    activeMenuItem?: string | Function;
    subRoutes?: boolean;
    isAnonymous?: boolean;
    requiredRoles?: string[];
}

const Fallback = LoadingPage;

const routeInfo: Path[] = [
    {
        path: "/challenges",
        component: ChallengeTilesList,
        activeMenuItem: "challenges",
    },
    {
        path: "/challenges/:columnId",
        component: KanbanColumnList,
        activeMenuItem: "challenges",
    },

    {
        path: "/ideas",
        component: IdeasKanbanOverview,
        activeMenuItem: "ideas",
    },
    {
        path: "/ideas/:columnId",
        component: KanbanColumnList,
        activeMenuItem: "ideas",
    },

    // Live and Results (Also handles legacy route redirects)
    {
        path: [
            "/challenge/:id/live",
            "/challenge/:id/results",
            "/challenge/:id/submissions",
            "/challenge/:id/forum",
            "/challenge/:id/updates",
            "/challenge/:id/participants",
        ],
        component: CompetitionRun,
        activeMenuItem: "challenge",
        subRoutes: true,
    },

    // Preview
    {
        path: ["/challenge/:id/preview"],
        component: ViewChallenge,
        activeMenuItem: "challenge",
    },

    // Opportunity or Build
    {
        path: ["/challenge/:id/:phase"],
        component: PhaseEditPage,
        activeMenuItem: "challenge",
    },

    { path: "/user/login", component: LoginPage, isAnonymous: true },
    { path: "/user/password", component: PasswordResetPage, isAnonymous: true },
    {
        path: "/user/set-new-password",
        component: SetNewPassword,
        isAnonymous: true,
    },

    { path: "/user/edit/:id", component: EditUser },
    { path: "/user/edit", component: EditUser },

    // Admin routes.
    {
        path: "/admin/task-templates/edit/:id",
        component: EditTaskTemplate,
        requiredRoles: ["innovation_manager"],
        activeMenuItem: "task_workflows",
    },
    {
        path: "/admin/task-templates",
        component: ManageTaskTemplates,
        requiredRoles: ["innovation_manager"],
        activeMenuItem: "task_workflows",
    },
    {
        path: "/admin/user-management",
        component: ManageUsers,
        requiredRoles: ["innovation_manager"],
        activeMenuItem: "user_management",
    },
    {
        path: "/admin/user/new",
        component: NewUser,
        requiredRoles: ["innovation_manager"],
        activeMenuItem: "user_management",
    },
    {
        path: "/admin/organisations/edit/:id",
        component: EditOrganisation,
        requiredRoles: ["innovation_manager"],
        activeMenuItem: "organisations",
    },
    {
        path: "/admin/organisations",
        component: ManageOrganisations,
        requiredRoles: ["innovation_manager"],
        activeMenuItem: "organisations",
    },
    {
        path: "/admin/tags",
        component: ManageTags,
        requiredRoles: ["innovation_manager"],
        activeMenuItem: "tags",
    },
    {
        path: "/admin/help",
        component: ManageHelp,
        requiredRoles: ["innovation_manager"],
        activeMenuItem: "help",
    },
    {
        path: "/admin/content/edit/:id",
        component: EditContentBlock,
        requiredRoles: ["innovation_manager"],
        activeMenuItem: "content",
    },
    {
        path: "/admin/content",
        component: ManageContent,
        requiredRoles: ["innovation_manager"],
        activeMenuItem: "content",
    },

    // Home
    { path: "/", component: Home, activeMenuItem: "home" },
];

interface Props {
    isLoggedIn: boolean;
    doLoadCurrentUser: Function;
    setIsLoggedIn: Function;
}

// Temporary workaround so that our actions can clear the cache of the API
// wrapped up in context.
const App: React.FC<Props> = ({
    isLoggedIn,
    doLoadCurrentUser,
    setIsLoggedIn,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isFailed] = useState<boolean>(false);
    const [roles, setRoles] = useState<any[]>([]);
    const [currentUser, setCurrentUser] = useState<any | null>(null);

    const backendApi = new BackendApi(() => jsonApi());
    setGlobalBackendApi(backendApi);

    const innovatorPortalClient = axios.create({
        baseURL: getBaseUrl(),
    });
    const innovatorApi = new BackendApi(() => innovatorPortalClient);

    const checkLogin = useCallback(() => {
        refreshToken()
            .then(() => {
                doLoadCurrentUser().then((userResponse: any) => {
                    setCurrentUser(userResponse.payload.data);
                    setRoles(
                        typeof userResponse.payload.included === "undefined"
                            ? []
                            : userResponse.payload.included.filter(
                                  (role: any) =>
                                      role.type === "user_role--user_role",
                              ),
                    );
                    setIsLoading(false);
                });
            })
            .catch(() => {
                // Show the login page.
                setIsLoading(false);
            });
    }, [doLoadCurrentUser]);

    useEffect(() => {
        checkLogin();
    }, [checkLogin]);

    useEffect(() => {
        if (currentUser) {
            setIsLoggedIn(true);
        }
    }, [currentUser, setIsLoggedIn]);

    const checkRouteAuth = (requiredRoles: any[]) => {
        let hasCorrectRoles = true;
        let allUserRoles = roles.map(
            role => role.attributes.drupal_internal__id,
        );

        requiredRoles.forEach(role => {
            if (!allUserRoles.includes(role)) {
                hasCorrectRoles = false;
            }
        });

        return hasCorrectRoles;
    };

    if (isLoading) {
        return (
            <LoadingPage>
                <GlobalStyles />
                <GlobalButtonStyles />
            </LoadingPage>
        );
    }

    if (isFailed) {
        return (
            <div style={{ marginTop: "64px" }}>
                <GenericError
                    title="The Challenge Platform is currently under maintenance"
                    message="Please try again shortly or contact Unearthed (dev@unearthed.solutions) if this persists."
                />
            </div>
        );
    }

    return (
        <Router basename={"/"}>
            <div>
                <Suspense fallback={<Fallback />}>
                    {/* @ts-ignore */}
                    <ApiContext.Provider value={backendApi}>
                        {/* @ts-ignore */}
                        <InnovatorApiContext.Provider value={innovatorApi}>
                            <GlobalStyles />
                            <GlobalButtonStyles />
                            <NeedsRefreshIndicator />
                            <Switch>
                                {routeInfo.map(route => {
                                    if (gaInit)
                                        route.component = withTracker(
                                            route.component,
                                        );
                                    return (
                                        <Route
                                            key="app_route"
                                            exact={!route.subRoutes}
                                            path={route.path}
                                            render={({ location, match }) => {
                                                const isAnonymous =
                                                    route.isAnonymous || false;
                                                const AuthedComponent = requiresAuth(
                                                    route.component,
                                                    routeInfo,
                                                    checkRouteAuth(
                                                        route.requiredRoles ||
                                                            [],
                                                    ),
                                                    isLoggedIn,
                                                    isAnonymous,
                                                    checkLogin,
                                                    location,
                                                );

                                                if (
                                                    isAnonymous === true ||
                                                    isLoggedIn !== true
                                                ) {
                                                    return AuthedComponent;
                                                }

                                                return (
                                                    <AppLayout
                                                        activeMenuItem={
                                                            typeof route.activeMenuItem ===
                                                            "function"
                                                                ? route.activeMenuItem(
                                                                      match,
                                                                  )
                                                                : route.activeMenuItem
                                                        }
                                                    >
                                                        <Suspense
                                                            fallback={
                                                                <Fallback />
                                                            }
                                                        >
                                                            {AuthedComponent}
                                                        </Suspense>
                                                    </AppLayout>
                                                );
                                            }}
                                        />
                                    );
                                })}
                                <Route>
                                    <AppLayout>
                                        <Suspense fallback={<Fallback />}>
                                            <PageNotFound404 />
                                        </Suspense>
                                    </AppLayout>
                                </Route>
                            </Switch>
                        </InnovatorApiContext.Provider>
                    </ApiContext.Provider>
                </Suspense>
            </div>
        </Router>
    );
};

const mapStateToProps = (state: any) => {
    return {
        isLoggedIn: state.appState.isLoggedIn,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        doLoadCurrentUser: () => dispatch(loadCurrentUser()),
        setIsLoggedIn: (...args: any) => dispatch(setIsLoggedIn(...args)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
